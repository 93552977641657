<template>
  <div>
    <div class="hxcp"></div>
    <!-- 内容一 -->
    <div class="content1 container">
      <show :styles="{ top: '5em', left: '-20em' }" :moveData="{ left: '6em' }">
        <div class="infos">
          <p class="title1">数据挖掘与分析平台</p>
          <p class="title2">LinkSmart</p>
          <p class="border"></p>
          <p class="text">
            LinkSmart数据挖掘与分析平台基于机器学习和深度学习框架，通过高级智能算
          </p>
          <p class="text">
            法，建立企业的知识图谱和神经网络结构，让机器有能力“自己思考”，形成具有
          </p>
          <p class="text">智慧决策能力的企业人工智能大脑。</p>
        </div>
      </show>
      <show :styles="{ top: '3em', left: '70em' }" :moveData="{ left: '50em' }">
        <img src="../../assets/images/bg_img/LinkSmart.jpg" alt="" />
      </show>
    </div>

    <!-- 内容二 -->
    <div class="content2 container">
      <show :styles="{ top: '3em', left: '50em' }" :moveData="{ left: '35em' }">
        <p class="common_title title_sty">平台特点</p></show
      >
      <show :styles="{ top: '12em', left: '-10em' }" :moveData="{ left: '8em' }">
        <img src="../../assets/images/bg_img/LinkSmart2.png" alt="" />
      </show>
      <show :styles="{ top: '10em', left: '70em' }" :moveData="{ left: '39.5em' }">
        <div class="specila_text">
          <p class="no">01</p>
          <p class="no_text">基于大数据的挖掘建模与智能分析</p>
        </div>
      </show>
      <show :styles="{ top: '10em', left: '70em' }" :moveData="{ left: '59em' }">
        <div class="specila_text">
          <p class="no">02</p>
          <p class="no_text">海量数据分析与实时处理能力</p>
        </div>
      </show>
      <show :styles="{ top: '18em', left: '70em' }" :moveData="{ left: '39.5em' }">
        <div class="specila_text">
          <p class="no">03</p>
          <p class="no_text">基于深度学习的AI算法，同时支持与第三方AI平台对接</p>
        </div>
      </show>
      <show :styles="{ top: '18em', left: '70em' }" :moveData="{ left: '59em' }">
        <div class="specila_text">
          <p class="no">04</p>
          <p class="no_text">基于成熟平台的算法模型，大大缩短应用落地周期</p>
        </div>
      </show>
      <show :styles="{ top: '26em', left: '70em' }" :moveData="{ left: '39.5em' }">
        <div class="specila_text">
          <p class="no">05</p>
          <p class="no_text">内置水力模型、漏损分析、图像识别等分析算法</p>
        </div>
      </show>
      <show :styles="{ top: '26em', left: '70em' }" :moveData="{ left: '59em' }">
        <div class="specila_text">
          <p class="no">06</p>
          <p class="no_text">为公用事业量身而作，更贴进实际应用场景</p>
        </div>
      </show>
    </div>

    <!-- 内容三 -->
    <div class="content3 container">
      <show :styles="{ top: '3em', left: '50em' }" :moveData="{ left: '35em' }">
        <p class="common_title title_sty">应用场景</p></show
      >
      <show :styles="{ top: '8em', left: '-20em' }" :moveData="{ left: '6.5em' }">
        <img src="../../assets/images/bg_img/LinkSmart3.jpg" alt="" />
      </show>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content1 {
  height: 29.3em;
  .title {
    width: 18em;
    font-size: 1.5em;
    font-weight: bold;
  }
  img {
    width: 19.625em;
  }

  .title1 {
    color: rgb(0, 112, 215);
    font-size: 1.9em;
  }
  .title2 {
    color: rgb(0, 112, 215);
    font-size: 2.75em;
    font-weight: bold;
  }
  .border {
    width: 5.375em;
    height: 4px;
    background-color: #626462;
    margin: 1.5em 0;
  }
  .text {
    width: 36em;
    font-size: 0.875em;
    color: #898989;
    margin-bottom: 0.5em;
  }
}
.content2 {
  height: 38.4375em;
  ul {
    width: 58em;
    display: flex;
    justify-content: space-between;
  }
  .title {
    font-weight: bold;
    font-size: 1.1em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
  .text {
    font-size: 0.85em;
    color: #cfcdcf;
  }
  img {
    width: 18.75em;
    height: 18.75em;
  }
  .specila_text {
    .no {
      color: #1f87e8;
      font-size: 2.25em;
      font-weight: bold;
      margin-bottom: 0.3em;
    }
    .no_text {
      width: 17em;
      font-size: 0.9em;
    }
  }
}

.content3 {
  height: 30.6875em;
  img {
    width: 65.5em;
  }
}
</style>
